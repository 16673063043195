<script lang="ts">
  import '../../app.pcss';
  import { LightSwitch } from '@skeletonlabs/skeleton';
  import { page } from '$app/stores';

  let menuItems = [
    { name: "Start", href: "/" },
    { name: "Teams", href: "/teams" },
    { name: "Hall of Fame", href: "/hall-of-fame" },
    { name: "Regeln", href: "/rules" },
  ];
</script>

<style>
  @font-face {
    font-family: 'October Crow';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/October Crow.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Justus Versalitas';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/justus-versalitas.ttf') format('truetype');
  }

  .event-title {
    position: relative;
    display: inline-block;
    text-align: center;
    line-height: normal;
  }
  .event-title div:nth-child(1) {
    font-family: 'October Crow';
    font-size: 2rem;
    font-weight: bold;
    color: #000;
    text-shadow: 0px 0px 6px #f00;
  }
  .event-title div:nth-child(2) {
    font-family: 'Justus Versalitas';
    font-size: 1rem;
    font-weight: bold;
    color: #000;
    text-shadow: 0px 0px 6px #f00;
  }
  .event-title a:nth-child(2) {
    position: absolute;
    right: -75px;
    top: 25px;
    font-weight: bold;
    color: #a970ff;
    text-shadow: 0px 0px 6px #fff;
    rotate: -15deg;
  }
  :global(.dark) .event-title a:nth-child(2) {
    text-shadow: 0px 0px 6px #000;
  }
  .event-title a:nth-child(2):hover {
    color: #000;
    text-shadow: 0px 0px 6px #f00;
  }

  .main.event-title div:nth-child(1) {
    font-size: 4rem;
  }
  .main.event-title div:nth-child(2) {
    font-size: 2rem;
  }
  .main.event-title a:nth-child(2) {
    font-size: 2rem;
    right: -150px;
    top: 50px;
  }

  .menu-item.active {
    text-shadow: 0px 0px 6px #f00;
  }
</style>

<LightSwitch class="absolute right-2 top-2" /> 

<div class="text-center mb-[30px]">
  <div class="event-title main">
    <a href="/">
      <div>The Haunting Games</div>
      <div>Hunt me if you can</div>
    </a>
    <a href="https://twitch.tv/hasgarson" target="_blank">by Hasgarson</a>
  </div>
</div>

<div class="grid grid-cols-4 gap-2 w-[500px] m-auto text-center mb-[20px] mt-[20px]">
  {#each menuItems as menuItem}
    <a href="{menuItem.href}" class="menu-item {$page.url.pathname === menuItem.href ? "active" : ""}">{menuItem.name}</a>
  {/each}
</div>

<div class="pb-[20px]">
  <slot />
</div>